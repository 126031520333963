.padding-login {
    padding: 0px !important;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.padding-footer-login {
    padding: 0px !important;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.back-drop-shadow {
    display: inline-flex;
    justify-content: center;
    background: #1d1d1d52;
    width: 100%;
    height: 100%;
}

.centerModal {
    /* display: inline-flex; */
    /* justify-content: center; */
    background: #1d1d1d52;
    /* width: 100%;
    height: 100%; */
    /* position: fixed; */
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    /* width: 48%;
    height: 59%; */
    /* align-items: center; */
    z-index: 100;
}
.modal-custom {
    display: inline-flex;
    justify-content: center;
    background: #1d1d1d52;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Yep! */
    /* width: 48%;
    height: 59%; */
    align-items: center;
    z-index: 100;
}

.modal-alert-s {
    background: #1c8e5f !important;
    color: #fff !important;
}

.modal-alert-e {
    background: #d11e43 !important;
    color: #fff !important;
    opacity: 1;
}

.modal-alert-l {
    background: #7b7b7b !important;
    color: #fff !important;
}

.modal-alert-s h5 {
    color: #fff !important;
}

.modal-alert-e h5 {
    color: #fff !important;
}

.modal-alert-l h5 {
    color: #fff !important;
}

.modal-content {
    min-width: 410px;
}

.btn-add {
    float: right;
    margin: 20px;
}

.center-text {
    display: inline-flex;
    justify-content: center;
}

.logo-expanded {
    height: 60px;
}

.navigations-rows-shows > h2 {
    font-size: 14px;
    margin: 10px;
}

.pagination .page-item .page-link {
    background: transparent;
    color: #2e323c;
    margin: 0;
    border: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    min-width: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 1px 1px 8px 0px #ddd;
}
.pagination .page-item .page-link:hover {
    background: #f2f4f9;
    color: #2e323c;
}
.pagination .page-item .page-link i {
    font-weight: 700;
    vertical-align: middle;
    font-size: 0.95rem;
}
.pagination .page-item.active .page-link {
    background: #192445;
    color: #ffffff;
    pointer-events: none;
    box-shadow: 0 0 0 0.2rem rgba(26, 142, 95, 0.2);
}
.pagination .page-item.disabled .page-link {
    background: #f2f4f9;
    color: #8796af;
}
.pagination.primary .page-item.active .page-link {
    background: #192445;
    box-shadow: 0 0 0 0.2rem rgba(26, 142, 95, 0.2);
}
.pagination.secondary .page-item.active .page-link {
    background: #cc2626;
    box-shadow: 0 0 0 0.2rem rgba(204, 38, 39, 0.2);
}
.pagination.success .page-item.active .page-link {
    background: #108c2a;
    box-shadow: 0 0 0 0.2rem rgba(16, 140, 42, 0.2);
}
.pagination.info .page-item.active .page-link {
    background: #1a538e;
    box-shadow: 0 0 0 0.2rem rgba(27, 82, 142, 0.2);
}
.pagination.warning .page-item.active .page-link {
    background: #c1920c;
    box-shadow: 0 0 0 0.2rem rgba(193, 146, 13, 0.2);
}
.pagination.danger .page-item.active .page-link {
    background: #ea490b;
    box-shadow: 0 0 0 0.2rem rgba(235, 73, 12, 0.2);
}
.pagination.rounded .page-item .page-link {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}
.pagination.rounded .page-item .page-link:hover {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}
.pagination.rounded .page-item.active .page-link {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.loading-table {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
}
.inline-flex-itens {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.center-div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.modal-content {
    min-width: 840px;
}

.modal-header .close {
    box-shadow: none;
    border: none;
}
.modal-header .close {
    background-color: #1a8e5f;
    opacity: 1;
}
@media screen and (max-width: 39.9375em) {
    .hide-for-small-only {
        display: none !important;
    }
    
    .modal-content {
        min-width: 350px !important;
    }

    .modal-pedido {
        width: 100% !important;
        padding: 0px;
    }

    .modal_ .modal-dialog {
        width: 100%;
        padding: 30px;
    }
    .padding-top-content {
        padding-top: 50px;
    }

    /* .custom-navbar ul.navbar-nav ul.dropdown-menu {
        position: absolute;
        z-index: 999;
        display: block;
        top: 50px;
    } */
}

.pag-custom {
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
    .show-for-small-only {
        display: none !important;
    }

    .pag-custom {
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        overflow: scroll;
    }
}

@media print, screen and (min-width: 40em) {
    .hide-for-medium {
        display: none !important;
    }
    .pag-custom {
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        overflow: auto;
    }
}

@media screen and (max-width: 39.9375em) {
    .show-for-medium {
        display: none !important;
    }
    .pag-custom {
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        overflow: scroll;
    }
    .QA_table {
        overflow: scroll;
    }
    .inline-flex-itens {
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .hide-for-medium-only {
        display: none !important;
    }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
    .show-for-medium-only {
        display: none !important;
    }
}

@media print, screen and (min-width: 64em) {
    .hide-for-large {
        display: none !important;
    }
}

@media screen and (max-width: 63.9375em) {
    .show-for-large {
        display: none !important;
    }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .hide-for-large-only {
        display: none !important;
    }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
    .show-for-large-only {
        display: none !important;
    }
}

.header-login {
    text-align: center;
}

.header-login h5 {
    margin: 10px;
}

.select-all-check {
    padding: 0 33px;
    padding-top: 20px;
}

.center-btn {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}
.btn-eye {
    background: #373063;
    color: #fff;
    cursor: pointer;
}

.modal-content {
    /* min-width: max-content;     */
    min-width: 480px;
}

input {
    text-align: left !important;
}

.modal-dialog {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    max-width: none !important;
    padding: 0 50px;
}
.centerModal {
    z-index: 9999;
}
.QA_section .QA_table tbody th,
.QA_section .QA_table tbody td {
    padding: 0px 10px;
    margin: 0px;
    height: 70px;
}

.modal-sm {
    width: 480px;
}

.title-header {
    font-size: 22px;
    font-weight: 700;
    color: #302d43;
    margin-left: 1rem;
}

.form-client{
    padding: 0 80px;
}

/* MOBILE CSS */
@media (max-width: 575.98px) {
    .header_iner {
        margin: 0;
    }

    .title-header {
        font-size: 0.6rem;
        margin: 0 30px;
    }

    .inline-inputs-center {
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }

    .inline-inputs-center .col-auto {
        width: 100%;
        text-align: center;
    }

    .inline-inputs-center .col-auto > input {
        text-align: center !important;
    }

    .monitor_list_widget {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .monitor_list_widget .simgle_monitor_list .simgle_monitor_count span {
        font-size: 13px;
    }

    .monitor_list_widget .simgle_monitor_list {
        margin-bottom: 20px;
        border: 0;
        padding: 0;
        margin-right: 0;
        border-right: 1px solid #ccc;
        padding-right: 5px;
        padding-left: 5px;
    }

    .monitor_list_widget .simgle_monitor_list:last-child {
        margin-bottom: 20px;
        border: 0;
        padding: 0;
        margin-right: 0;
    }
    .monitor_list_widget .simgle_monitor_list h4 {
        font-size: 16px;
    }
    .btn-add-win {
        display: none;
    }

    .btn-add-m {
        display: block;
    }

    .QA_section {
        display: flex;
        flex-direction: column;
    }

    .white_card .white_card_body{
        padding: 10px 2px;
    }
    .hide-mobile{
        display: none;
    }
    .form-client{
        padding: 5px;
    }
}


.desktop-view {
    display: none;
}

.desktop-view {
    display: none;
}

@media (min-width: 768px) {
    .desktop-view {
        display: block;
    }

    .mobile-view {
        display: none;
    }
}

@media (max-width: 767px) {
    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }
}

.header-painel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1rem;
    background-color: #fff;
}

.icon-sidebar,
.div-profile {
    cursor: pointer;
}

.div-title,
.profile-details {
    display: flex;
    align-items: center;
}

.div-title h4,
.profile-details .author-name {
    margin: 0;
    padding: 0;
}

.div-title .title {
    margin-left: 0.5rem;
}

.profile-details img {
    max-width: 54px;
    max-height: 54px;
    border-radius: 50%;
}

@media (max-width: 991px) {
    .profile-details img {
        max-width: 30px;
    }
}

.profile-details .author-name {
    color: #434750;
    line-height: 18px;
}

.profile-details .author-name span {
    color: #9b9aba;
}

.profile-info-inner {
    position: absolute;
    right: 0;
    background-color: #fff;
    text-align: left;
    width: 215px;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    top: 77px;
    transition: opacity 0.5s, visibility 0.5s;
    border-radius: 0;
    box-shadow: 0 0 2px 2px rgba(62, 217, 124, 0.1);
    z-index: 99;
}

.profile-info-inner::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: -10px;
    right: 15px;
    border-width: 0 11px 12px;
    border-color: transparent transparent #373063 transparent;
    z-index: 2;
}

.profile-info-inner .profile-author-name {
    padding: 20px 25px;
    background: #373063;
}

.div-profile .profile-info-inner a {
    display: block;
}

.div-profile .profile-info-inner p,
.div-profile .profile-info-inner h5 {
    color: #fff;
    margin: 0;
}

.div-profile:hover .profile-info-inner {
    opacity: 1;
    visibility: visible;
    top: 77px;
}

.profile-info-details {
    padding: 20px 25px;
}

.profile-info-details a {
    color: #2e4765;
    font-size: 14px;
    display: block;
    padding: 10px 0;
}

.profile-info-details a:hover,
.profile-info-details a:hover i {
    color: #2e4765;
}

.profile-info-details i {
    color: #2e4765;
    font-size: 12px;
    margin-left: 20px;
    transition: color 0.5s;
}
